import { graphql, useMutation } from 'react-relay';

import { RefreshCampaignPerformanceMetricsQuery } from 'GraphTypes/RefreshCampaignPerformanceMetricsQuery.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const RefreshCampaignPerformanceMetricsQueryMutation = graphql`
  mutation RefreshCampaignPerformanceMetricsQuery($input: RefreshCampaignPerformanceMetricsInput!) {
    refreshCampaignPerformanceMetrics(input: $input) {
      ... on RefreshCampaignPerformanceMetricsPayload {
        performanceMetricsSyncRequest {
          campaign {
            latestPerformanceMetricsSyncRequest {
              id
              status
            }
            latestPostPublicationsUpdatedAt
            counters {
              performanceSummary {
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;

export const useRefreshCampaignPerformanceMetricsQueryMutation: BaseMutation<
  RefreshCampaignPerformanceMetricsQuery
> = (config) => {
  const [refreshCampaignPerformanceMetricsQuery, loading] =
    useMutation<RefreshCampaignPerformanceMetricsQuery>(
      RefreshCampaignPerformanceMetricsQueryMutation
    );

  const refreshCampaignPerformanceMetricsQueryCaller: BaseMutationExecFn<
    RefreshCampaignPerformanceMetricsQuery
  > = (execConfig) => {
    refreshCampaignPerformanceMetricsQuery({
      ...config,
      ...execConfig,
    });
  };
  return [refreshCampaignPerformanceMetricsQueryCaller, loading];
};
