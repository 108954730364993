import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CreativeList from './CreativeList/CreativeList';
import CampaignData from './CampaignData/CampaignData';
import BriefInfo from './BriefInfo/BriefInfo';

import { Currency } from 'GraphTypes/ListingBriefQuery.graphql';
import { BriefContent_campaign$key } from 'GraphTypes/BriefContent_campaign.graphql';

interface Props {
  campaign?: BriefContent_campaign$key | null;
  isCreator?: boolean;
  mobileView?: boolean;
  currency?: Currency | null;
  compactView?: boolean;
  shortenView?: boolean;
}

const BriefContent: React.FC<Props> = (props) => {
  const { campaign, isCreator, mobileView, currency, compactView, shortenView } = props;

  const data = useFragment(
    graphql`
      fragment BriefContent_campaign on Campaign {
        id
        ...CampaignData_campaign
        ...CreativeList_campaign
        ...BriefInfo_campaign
      }
    `,
    campaign
  );

  if (!data) return null;

  return (
    <>
      <CampaignData
        currency={currency}
        campaign={data}
        isCreator={!!isCreator}
        mobileView={mobileView}
        compactView={compactView}
        shortenView={shortenView}
      />
      <CreativeList campaign={data} mobileView={mobileView} />
      <BriefInfo campaign={data} mobileView={mobileView} />
    </>
  );
};

export default BriefContent;
