/**
 * @generated SignedSource<<ab70e0620ec1a36e4657e94b09430e3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PerformanceMetricsSyncRequestStatus = "COMPLETED" | "FAILED" | "PENDING";
export type RefreshCampaignPerformanceMetricsInput = {
  campaignId: string;
  clientMutationId?: string | null | undefined;
};
export type RefreshCampaignPerformanceMetricsQuery$variables = {
  input: RefreshCampaignPerformanceMetricsInput;
};
export type RefreshCampaignPerformanceMetricsQuery$data = {
  readonly refreshCampaignPerformanceMetrics: {
    readonly performanceMetricsSyncRequest?: {
      readonly campaign: {
        readonly counters: {
          readonly performanceSummary: {
            readonly updatedAt: any;
          } | null | undefined;
        } | null | undefined;
        readonly latestPerformanceMetricsSyncRequest: {
          readonly id: string;
          readonly status: PerformanceMetricsSyncRequestStatus;
        } | null | undefined;
        readonly latestPostPublicationsUpdatedAt: any | null | undefined;
      };
    };
  } | null | undefined;
};
export type RefreshCampaignPerformanceMetricsQuery = {
  response: RefreshCampaignPerformanceMetricsQuery$data;
  variables: RefreshCampaignPerformanceMetricsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PerformanceMetricsSyncRequest",
  "kind": "LinkedField",
  "name": "latestPerformanceMetricsSyncRequest",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestPostPublicationsUpdatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshCampaignPerformanceMetricsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshCampaignPerformanceMetrics",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PerformanceMetricsSyncRequest",
                "kind": "LinkedField",
                "name": "performanceMetricsSyncRequest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignCounters",
                        "kind": "LinkedField",
                        "name": "counters",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CampaignPerformanceSummary",
                            "kind": "LinkedField",
                            "name": "performanceSummary",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshCampaignPerformanceMetricsPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshCampaignPerformanceMetricsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshCampaignPerformanceMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PerformanceMetricsSyncRequest",
                "kind": "LinkedField",
                "name": "performanceMetricsSyncRequest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignCounters",
                        "kind": "LinkedField",
                        "name": "counters",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CampaignPerformanceSummary",
                            "kind": "LinkedField",
                            "name": "performanceSummary",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "RefreshCampaignPerformanceMetricsPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7cf7678639bbe7f48b8fddf7137cd77a",
    "id": null,
    "metadata": {},
    "name": "RefreshCampaignPerformanceMetricsQuery",
    "operationKind": "mutation",
    "text": "mutation RefreshCampaignPerformanceMetricsQuery(\n  $input: RefreshCampaignPerformanceMetricsInput!\n) {\n  refreshCampaignPerformanceMetrics(input: $input) {\n    __typename\n    ... on RefreshCampaignPerformanceMetricsPayload {\n      performanceMetricsSyncRequest {\n        campaign {\n          latestPerformanceMetricsSyncRequest {\n            id\n            status\n          }\n          latestPostPublicationsUpdatedAt\n          counters {\n            performanceSummary {\n              updatedAt\n              id\n            }\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b63f8b1e870ede76c21268baed65130f";

export default node;
