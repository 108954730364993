import { ContentType } from 'GraphTypes/Brief_project.graphql';
import {
  VERTICAL_VIDEO,
  HORIZONTAL_VIDEO,
  SQUARE_VIDEO,
  BOOMERANG,
  IMAGE_TYPE,
} from 'Constants/general';

export const getIcon = (label?: string | null) => {
  if (!label) return '';

  if (label === 'instagram') {
    return 'instagram';
  } else if (label === 'tiktok') {
    return 'tiktok';
  } else if (label === 'youtube') {
    return 'youtube';
  } else if (label === 'snapchat') {
    return 'snapchat';
  } else if (label === 'facebook') {
    return 'facebook';
  } else if (label === 'other') {
    return 'more';
  }

  return '';
};

export const getCreativeType = (type?: ContentType | null) => {
  if (!type) return '';

  if (type === VERTICAL_VIDEO) {
    return 'verticalVideo';
  } else if (type === HORIZONTAL_VIDEO) {
    return 'horizontalVideo';
  } else if (type === SQUARE_VIDEO) {
    return 'squareVideo';
  } else if (type === BOOMERANG) {
    return 'boomerang';
  } else if (type === IMAGE_TYPE) {
    return 'image';
  }

  return '';
};
