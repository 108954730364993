import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { Button } from '@insensepro/ui-kit';
import { ReloadIcon } from '@hugeicons-pro/core-stroke-rounded';

import { amplitude } from 'Helpers/amplitude';
import { useRefreshCampaignPerformanceMetricsQueryMutation } from 'Mutations/RefreshCampaignPerformanceMetrics.Mutation';
import { RefreshDataButtonQuery as QueryType } from 'GraphTypes/RefreshDataButtonQuery.graphql';

interface Props {
  source?: 'overview' | 'posted_contet';
  campaignId: string;
}

const RefreshDataButtonQuery = graphql`
  query RefreshDataButtonQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      latestPerformanceMetricsSyncRequest {
        id
        status
      }
    }
  }
`;

const RefreshDataButton: React.FC<Props> = (props) => {
  const { campaignId, source } = props;

  const data = useLazyLoadQuery<QueryType>(RefreshDataButtonQuery, { campaignId });

  const status = data.campaign?.latestPerformanceMetricsSyncRequest?.status;

  const [refreshCampaignPerformanceMetrics, loading] =
    useRefreshCampaignPerformanceMetricsQueryMutation();

  const handleClick = () => {
    if (source === 'overview') {
      amplitude.sendEvent<542>({
        id: '542',
        category: 'campaign_performance',
        name: 'overview_refresh_data_click',
        param: { campaign_id: campaignId },
      });
    } else if (source === 'posted_contet') {
      amplitude.sendEvent<543>({
        id: '543',
        category: 'campaign_performance',
        name: 'posted_refresh_data_click',
        param: { campaign_id: campaignId },
      });
    }
    refreshCampaignPerformanceMetrics({ variables: { input: { campaignId } } });
  };

  return (
    <Button
      type={status === 'FAILED' ? 'destructive' : 'secondary'}
      style="heavy"
      size="small"
      msg={
        status === 'FAILED'
          ? 'campaign.overview.posting_overview.retry'
          : 'campaign.overview.posting_overview.refresh_data'
      }
      leftIcon={ReloadIcon}
      loading={loading || status === 'PENDING'}
      onClick={handleClick}
    />
  );
};

export default RefreshDataButton;
