/**
 * @generated SignedSource<<8a816e167c9b174bbfbbed0692c3579d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type PerformanceMetricsSyncRequestStatus = "COMPLETED" | "FAILED" | "PENDING";
export type PerformanceMetricsSyncRequestCompletedSubscription$variables = Record<PropertyKey, never>;
export type PerformanceMetricsSyncRequestCompletedSubscription$data = {
  readonly performanceMetricsSyncRequestCompleted: {
    readonly campaign: {
      readonly counters: {
        readonly performanceSummary: {
          readonly assetsCreated: number | null | undefined;
          readonly assetsPosted: number | null | undefined;
          readonly avgCpe: number | null | undefined;
          readonly avgCpm: number | null | undefined;
          readonly avgEngagementRate: number | null | undefined;
          readonly totalComments: number | null | undefined;
          readonly totalCost: number | null | undefined;
          readonly totalEngagement: number | null | undefined;
          readonly totalHired: number | null | undefined;
          readonly totalLikes: number | null | undefined;
          readonly totalReach: number | null | undefined;
          readonly totalSaves: number | null | undefined;
          readonly totalShares: number | null | undefined;
          readonly totalViews: number | null | undefined;
          readonly updatedAt: any;
        } | null | undefined;
      } | null | undefined;
      readonly latestPerformanceMetricsSyncRequest: {
        readonly id: string;
        readonly status: PerformanceMetricsSyncRequestStatus;
      } | null | undefined;
      readonly latestPostPublicationsUpdatedAt: any | null | undefined;
    };
  };
};
export type PerformanceMetricsSyncRequestCompletedSubscription = {
  response: PerformanceMetricsSyncRequestCompletedSubscription$data;
  variables: PerformanceMetricsSyncRequestCompletedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PerformanceMetricsSyncRequest",
  "kind": "LinkedField",
  "name": "latestPerformanceMetricsSyncRequest",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestPostPublicationsUpdatedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetsCreated",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetsPosted",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgCpe",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgCpm",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgEngagementRate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalComments",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCost",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalEngagement",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHired",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalLikes",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalReach",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSaves",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalShares",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalViews",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceMetricsSyncRequestCompletedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PerformanceMetricsSyncRequest",
        "kind": "LinkedField",
        "name": "performanceMetricsSyncRequestCompleted",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignCounters",
                "kind": "LinkedField",
                "name": "counters",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignPerformanceSummary",
                    "kind": "LinkedField",
                    "name": "performanceSummary",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PerformanceMetricsSyncRequestCompletedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PerformanceMetricsSyncRequest",
        "kind": "LinkedField",
        "name": "performanceMetricsSyncRequestCompleted",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignCounters",
                "kind": "LinkedField",
                "name": "counters",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignPerformanceSummary",
                    "kind": "LinkedField",
                    "name": "performanceSummary",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d58619ab53fcdfedcd28f3e0ebe260de",
    "id": null,
    "metadata": {},
    "name": "PerformanceMetricsSyncRequestCompletedSubscription",
    "operationKind": "subscription",
    "text": "subscription PerformanceMetricsSyncRequestCompletedSubscription {\n  performanceMetricsSyncRequestCompleted {\n    campaign {\n      latestPerformanceMetricsSyncRequest {\n        id\n        status\n      }\n      latestPostPublicationsUpdatedAt\n      counters {\n        performanceSummary {\n          updatedAt\n          assetsCreated\n          assetsPosted\n          avgCpe\n          avgCpm\n          avgEngagementRate\n          totalComments\n          totalCost\n          totalEngagement\n          totalHired\n          totalLikes\n          totalReach\n          totalSaves\n          totalShares\n          totalViews\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "edf63b9519dccbb1ee31e96471b279a2";

export default node;
