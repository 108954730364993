import React, { useContext } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import BriefContent from '../BriefContent/BriefContent';
import ProjectAcceptActions from '../ProjectAcceptActions/ProjectAcceptActions';

import styles from './ProjectBrief.css';

import markOfferAsRead from 'Mutations/MarkOfferAsRead.Mutation';
import markProjectEventsAsRead from 'Mutations/MarkProjectEventsAsRead.Mutation';
import environment from 'Api/Environment';
import Spinner from 'Atoms/Spinner/Spinner';
import { ProjectBriefQuery as QueryItem } from 'GraphTypes/ProjectBriefQuery.graphql';
import { BARTER_TYPE, CONTRACTOR, DASHBOARD_ROUTE } from 'Constants/general';
import { campaignPausedInfo } from 'Constants/messageData';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const ProjectBriefQuery = graphql`
  query ProjectBriefQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      currency
      campaignActivationPaused
      offer {
        id
        acceptance {
          id
        }
        rejection {
          id
        }
      }
      events(last: 1) {
        edges {
          node {
            id
          }
        }
      }
      campaign {
        id
        type
        useCase
        platform
        paymentType
        private
        completion {
          id
        }
        organization {
          tiktokShopLinked
        }
        ...BriefContent_campaign
      }
      paidSocial
      creator {
        username
        ... on InstagramCreator {
          paidSocialEnabled
          insightsAuthorized
        }
        ... on TiktokCreator {
          name
          tiktokAdsAuthorized
        }
        profile {
          currency
        }
      }
      brief {
        id
        __typename
        ... on V2Brief {
          paidSocialActivationDays
          productDelivery
          paidSocialBca
        }
      }
    }
    currentUser {
      type
      contractorProfile {
        tiktokShopLinked
      }
    }
  }
`;

let analyticsSent = false;

interface Props {
  projectId: string;
}

const ProjectBrief: React.FC<Props> = (props) => {
  const { projectId } = props;

  const tracking = useTracking();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const navigate = useNavigate();

  return (
    <QueryRenderer<QueryItem>
      environment={environment}
      query={ProjectBriefQuery}
      variables={{
        projectId,
      }}
      render={({ props: queryProps }) => {
        if (!queryProps) {
          return (
            <div style={{ margin: '40px auto', display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </div>
          );
        }

        const project = queryProps.project;

        const campaign = project?.campaign;
        const campaignId = project?.campaign.id;
        const campaignType = project?.campaign.type;
        const campaignPrivate = project?.campaign.private;
        const campaignPlatform = project?.campaign.platform;
        const campaignCompletion = project?.campaign.completion?.id;
        const currency = project?.currency;

        const creatorCurrency = project?.creator?.profile?.currency;
        const tiktokAdsAuthorized = project?.creator?.tiktokAdsAuthorized || false;

        const campaignActivationPaused = project?.campaignActivationPaused;

        const paymentType = project?.campaign.paymentType;
        const isBarter = paymentType === BARTER_TYPE;

        const userType = queryProps.currentUser?.type;
        const isCreator = userType === CONTRACTOR;

        const acceptance = project?.offer?.acceptance;

        const needAcceptance = project?.offer && !acceptance && !project.offer.rejection;

        const offerId = project?.offer?.id;
        const projectId = project?.id;
        const paidSocial = project?.paidSocial;
        const paidSocialBca = project?.brief?.paidSocialBca;
        const username = project?.creator?.username;
        const insightsAuthorized = project?.creator?.insightsAuthorized;
        const paidSocialEnabled = project?.creator?.paidSocialEnabled;
        const psDays = project?.brief?.paidSocialActivationDays;
        const productDelivery = project?.brief?.productDelivery;

        if (!analyticsSent) {
          if (offerId) {
            markOfferAsRead({ offerId });
            if (!acceptance) {
              const lastEventId = project?.events?.edges
                ? project?.events?.edges[0]?.node?.id
                : undefined;
              if (lastEventId && projectId) {
                markProjectEventsAsRead({ uptoEventId: lastEventId, projectId });
              }
            }
          }
          if (campaignActivationPaused && !acceptance) {
            tracking.trackEvent({
              amplitude: true,
              event_id: 'd-v1-e19',
              event_cat: 'pageview',
              event_name: 'brief_of_paused_campaign',
              event_param: { type: 'brief', creatorCurrency: currency, campaignId },
            });
          }
          tracking.trackEvent({
            event: 'open',
            goal: 'contractor_brief_open',
            payload: { campaignType, campaignPrivate, paymentType },
          });
          tracking.trackEvent({
            amplitude: true,
            event_id: 'd-v1-e9',
            event_cat: 'brief',
            event_name: 'preview',
            event_param: {
              platform: campaignPlatform,
              campaignType,
              campaignPrivate,
              paymentType,
              campaignId,
            },
          });
          analyticsSent = true;
        }

        if ((campaignActivationPaused || campaignCompletion) && !acceptance) {
          const handleViewOtherClick = () => {
            tracking.trackEvent({
              amplitude: true,
              event_id: 'd-v1-e20',
              event_cat: 'campaign',
              event_name: 'view_other_click',
              event_param: { type: 'brief', creatorCurrency, campaignId },
            });
            navigate(DASHBOARD_ROUTE);
          };
          modalDispatch({
            type: ModalTypes.SET_MODAL,
            payload: {
              name: modalName.SIMPLE,
              attach: {
                ...campaignPausedInfo,
                closeDisable: true,
                type: 'full',
                buttonData: { ...campaignPausedInfo.buttonData, onClick: handleViewOtherClick },
              },
            },
          });
          return null;
        }

        return (
          <div>
            <div className={styles.content}>
              <BriefContent campaign={campaign} currency={currency} isCreator={isCreator} />
            </div>
            {isCreator && needAcceptance && (
              <ProjectAcceptActions
                offerId={offerId}
                projectId={projectId}
                paidSocial={paidSocial}
                paidSocialBca={paidSocialBca}
                paidSocialEnabled={paidSocialEnabled}
                insightsAuthorized={insightsAuthorized}
                psDays={psDays}
                isBarter={isBarter}
                username={username}
                productDelivery={productDelivery}
                needIgInsights={
                  campaignType === 'INFLUENCER' &&
                  (campaignPlatform === 'FACEBOOK' || campaignPlatform === 'INSTAGRAM')
                }
                needTiktokAuthorization={
                  campaignType === 'INFLUENCER' && campaignPlatform === 'TIKTOK'
                }
                needTiktokShopAuthorization={
                  campaign?.useCase === 'TIKTOK_SHOP' &&
                  !queryProps.currentUser?.contractorProfile?.tiktokShopLinked &&
                  campaign.organization?.tiktokShopLinked
                }
                tiktokAdsAuthorized={tiktokAdsAuthorized}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default track()(ProjectBrief);
