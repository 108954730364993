import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';

const subscription = graphql`
  subscription PerformanceMetricsSyncRequestCompletedSubscription {
    performanceMetricsSyncRequestCompleted {
      campaign {
        latestPerformanceMetricsSyncRequest {
          id
          status
        }
        latestPostPublicationsUpdatedAt
        counters {
          performanceSummary {
            updatedAt
            assetsCreated
            assetsPosted
            avgCpe
            avgCpm
            avgEngagementRate
            totalComments
            totalCost
            totalEngagement
            totalHired
            totalLikes
            totalReach
            totalSaves
            totalShares
            totalViews
          }
        }
      }
    }
  }
`;

export default () => {
  requestSubscription(environment, {
    subscription,
    variables: {},
  });
};
