import React, { useContext, useEffect } from 'react';
import track, { useTracking } from 'react-tracking';
import { useNavigate } from 'react-router-dom';
import { fetchQuery } from 'relay-runtime';

import styles from './ProjectAcceptActions.css';

import { barterAcceptionData, productPurchaseData } from 'Constants/messageModal';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import { fbAuth } from 'Util/fbAuth';
import { getProjectBriefLink } from 'Util/links';
import authorizeInstagramInsights from 'Mutations/AuthorizeInstagramInsights.Mutation';
import authorizePaidSocial from 'Mutations/AuthorizePaidSocial.Mutation';
import Button from 'Atoms/Button/Button';
import NewButton from 'Components/ui/Button/Button';
import { ProductDeliveryOption } from 'GraphTypes/ProjectBriefQuery.graphql';
import { modalName } from 'Types/modals';
import { igInsightsFailed } from 'Constants/messageData';
import { AuthorizeInstagramInsightsMutation$data } from 'GraphTypes/AuthorizeInstagramInsightsMutation.graphql';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import currentUserQuery from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import { CONTRACTOR, SIGNIN_CREATOR_ROUTE } from 'Constants/general';
import environment from 'Api/Environment';
import type { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import { wait } from 'Util/wait';
import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const REJECTION_ACTION = 'rejection';

interface Props {
  offerId?: string;
  projectId?: string;
  currency?: string | null;
  username?: string;
  paidSocial?: boolean;
  paidSocialBca?: boolean;
  paidSocialEnabled?: boolean;
  insightsAuthorized?: boolean;
  psDays?: number | null;
  isBarter?: boolean;
  needAuth?: boolean;
  needSignin?: boolean;
  needLogout?: boolean;
  needConfirm?: boolean;
  needIgInsights?: boolean;
  productDelivery?: ProductDeliveryOption | null;
  needTiktokShopAuthorization?: boolean | null;
  handleAcceptOfferModalClick?: () => void;
  showAcceptActionOnly?: boolean;
}

const ProjectAcceptActions: React.FC<Props> = (props) => {
  const {
    offerId,
    projectId,
    paidSocial,
    paidSocialBca,
    paidSocialEnabled,
    insightsAuthorized,
    username,
    psDays,
    needAuth,
    needSignin,
    needLogout,
    needConfirm,
    needIgInsights,
    isBarter,
    currency,
    productDelivery,
    needTiktokShopAuthorization,
    handleAcceptOfferModalClick,
    showAcceptActionOnly,
  } = props;

  const { signUpWithPopup } = useContext(Auth0Context);
  const { authorize: authorizeTiktokShop } = useTikTokShopAuth('CREATOR');
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const tracking = useTracking();
  const navigate = useNavigate();

  const briefLink = projectId ? getProjectBriefLink({ projectId }) : '';

  useEffect(() => {
    const { action } = getQueryLocationParams();
    if (!action) return;

    if (action === REJECTION_ACTION) {
      handleRejectClick();
    } else {
      handleApplyClick();
    }
  }, []);

  const handleRejectClick = (trackData?: object) => {
    tracking.trackEvent({
      goal: 'contractor_brief_reject_click',
      element: 'reject',
      event: 'clicked',
      ...trackData,
    });

    if (needLogout) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.RESERVED_PROFILE,
          attach: { projectId },
        },
      });

      return;
    }

    if (needSignin) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.BRIEF_OFFER_REACTION,
          attach: {
            currency,
            offerId,
            projectId,
            isRejection: true,
          },
        },
      });

      return;
    }

    if (needConfirm) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.REJECT_OFFER,
          attach: { offerId, projectId, confirmProfile: true },
        },
      });

      return;
    }

    if (needAuth) {
      navigate(`${briefLink}?action=rejection&userType=contractor`);
    } else {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.REJECT_OFFER,
          attach: { offerId, projectId },
        },
      });
    }
  };

  const handleApplyClick = async (trackData?: object) => {
    tracking.trackEvent({
      goal: 'contractor_brief_apply_click',
      element: 'apply',
      event: 'clicked',
      ...trackData,
    });

    if (needLogout) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.RESERVED_PROFILE,
          attach: { projectId, callback: navigate(SIGNIN_CREATOR_ROUTE) },
        },
      });
      return;
    }

    if (needSignin) {
      await signUpWithPopup(CONTRACTOR);
      await wait(2000);
      await new Promise<void>((resolve, reject) => {
        fetchQuery<CurrentUserQuery>(environment, currentUserQuery, {}).subscribe({
          next: (result) => {
            if (result?.currentUser?.type === CONTRACTOR) {
              modalDispatch({
                type: ModalTypes.SET_MODAL,
                payload: {
                  name: modalName.BRIEF_OFFER_REACTION,
                  attach: { currency, projectId, offerId },
                },
              });
              resolve();
            }
          },
          error: () => reject(),
        });
      });

      return;
    }

    if (needConfirm) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.ACCEPT_OFFER,
          attach: { projectId, confirmProfile: true },
        },
      });
      return;
    }

    if (needAuth) {
      const actionName =
        !paidSocialEnabled && (paidSocial || paidSocialBca) ? 'connect-to-fb' : 'apply';
      navigate(`${briefLink}?action=${actionName}&userType=contractor`);
      return;
    }

    if (needTiktokShopAuthorization) {
      const authData = await authorizeTiktokShop();
      if (authData?.data?.__typename !== 'AuthorizeTiktokShopUserPayload') {
        return;
      }
    }

    if ((!paidSocialEnabled || !insightsAuthorized) && (paidSocial || paidSocialBca)) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.CONNECT_PS,
          attach: {
            username,
            projectId,
            apply: true,
            psDays,
            psBca: paidSocialBca,
          },
        },
      });
    } else if (!insightsAuthorized && needIgInsights) {
      fbAuth({ successCallback: handleConectDone, failCallback: handleInsightsAuthDone });
    } else {
      if (handleAcceptOfferModalClick) {
        handleAcceptOfferModalClick();
      } else {
        modalDispatch({
          type: ModalTypes.SET_MODAL,
          payload: {
            name: modalName.ACCEPT_OFFER,
            attach: {
              projectId,
            },
          },
        });
      }
    }
  };

  const handleInsightsAuthDone = (data?: AuthorizeInstagramInsightsMutation$data) => {
    if (data?.authorizeInstagramInsights?.result === 'SUCCESS') {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.ACCEPT_OFFER,
          attach: {
            projectId,
          },
        },
      });
    } else {
      handleIgInsightsError();
    }
  };

  const handleIgInsightsError = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.SIMPLE,
        attach: {
          ...igInsightsFailed,
          buttonData: {
            msg: 'connect_fb_modal.retry',
            onClick: () => {
              fbAuth({ successCallback: handleConectDone, failCallback: handleInsightsAuthDone });
            },
          },
        },
      },
    });
  };

  const handleConectDone = (socialIdentityId: string) => {
    if (username) {
      if (!paidSocialEnabled) authorizePaidSocial({ instagramUsername: username, projectId });
      if (socialIdentityId) {
        authorizeInstagramInsights(
          { instagramUsername: username, socialIdentityId },
          handleInsightsAuthDone,
          handleIgInsightsError
        );
      }
    }
  };

  const handleShoppingProductApply = () => {
    tracking.trackEvent({
      goal: 'contractor_brief_apply_click',
      element: 'apply',
      event: 'clicked',
    });

    const handleRejectInModal = () => {
      handleRejectClick();
    };

    const handleApplyInModal = () => {
      handleApplyClick();
    };

    const el = (
      <div className={styles.buttonsInModal}>
        <Button
          color="normal"
          size="lg"
          msg="brief_modal.reject"
          className={styles.button}
          onClick={handleRejectInModal}
        />
        <Button
          size="lg"
          msg="brief_modal.agree"
          className={styles.button}
          onClick={handleApplyInModal}
        />
      </div>
    );
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.MESSAGE,
        attach: { ...productPurchaseData, el },
      },
    });
  };

  const handleApplyBarterClick = () => {
    tracking.trackEvent({
      goal: 'contractor_brief_apply_click',
      element: 'apply',
      event: 'clicked',
    });

    const handleRejectInModal = () => {
      if (productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT') {
        handleShoppingProductApply();
      } else {
        handleRejectClick({
          subsection: 'barter_modal',
          goal: 'contractor_barter_warning_modal_reject_click',
        });
      }
    };

    const handleApplyInModal = () => {
      if (productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT') {
        handleShoppingProductApply();
      } else {
        handleApplyClick({
          subsection: 'barter_modal',
          goal: 'contractor_barter_warning_modal_apply_click',
        });
      }
    };

    const el = (
      <div className={styles.buttonsInModal}>
        <Button
          color="normal"
          size="lg"
          msg="brief_modal.reject"
          className={styles.button}
          onClick={handleRejectInModal}
        />
        <Button
          size="lg"
          msg="brief_modal.agree"
          className={styles.button}
          onClick={handleApplyInModal}
        />
      </div>
    );
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.MESSAGE,
        attach: { ...barterAcceptionData, el },
      },
    });
  };

  const handleApplyFn =
    productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT'
      ? handleShoppingProductApply
      : handleApplyClick;

  const applyClick = isBarter && !needAuth ? handleApplyBarterClick : handleApplyFn;

  const rejectOffer = () => {
    handleRejectClick();
  };

  const applyOffer = () => {
    applyClick();
  };

  const applyBtnMsg = needSignin || needAuth ? 'brief_modal.im_interested' : 'brief_modal.apply';

  if (showAcceptActionOnly) {
    return <NewButton fluid color="black" msg={applyBtnMsg} onClick={applyOffer} />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Button
          color="normal"
          size="lg"
          msg="brief_modal.reject"
          className={styles.button}
          onClick={rejectOffer}
        />
        <Button size="lg" msg={applyBtnMsg} className={styles.button} onClick={applyOffer} />
      </div>
    </div>
  );
};

export default track({ subsection: 'buttons' })(ProjectAcceptActions);
