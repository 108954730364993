import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Button, Text } from '@insensepro/ui-kit';

import styles from './ApprovedCreative.pcss';

import { amplitude } from 'Helpers/amplitude';
import { getProjectsLink } from 'Util/links';
import { createDate } from 'Util/dateCreator';
import { getCreatorData } from 'Util/creatorData';
import Image from 'Components/ui/Image/Image';
import instagramColoredImg from 'Images/general/instagramColored.svg';
import tiktokColoredImg from 'Images/general/tiktokColored.svg';
import insenseIconImg from 'Images/icons/vetted.png';
import { ApprovedCreative_creative$key } from 'GraphTypes/ApprovedCreative_creative.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { MEDIA } from 'Constants/general';
import { CREATIVE_PREVIEW } from 'Constants/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  admin?: boolean;
  creative: ApprovedCreative_creative$key;
}

const ApprovedCreative: React.FC<Props> = (props) => {
  const { creative, admin } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const data = useFragment(
    graphql`
      fragment ApprovedCreative_creative on Creative {
        id
        file {
          createdAt
          type
          ... on Image {
            transformations {
              postThumbnailUrl
            }
          }
          ... on Video {
            transformations {
              autoUrl
              postThumbnailUrl
            }
          }
        }
        postPublication {
          id
        }
        project {
          id
          campaign {
            id
            stage
            organization {
              id
            }
          }
          creator {
            id
            username
            type
            __typename
            ... on InstagramCreator {
              user {
                id
              }
            }
            ... on TiktokCreator {
              tiktokUser: user {
                id
              }
            }
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
    creative
  );

  const creator = data.project?.creator;
  const projectId = data.project?.id || '';
  const campaign = data.project?.campaign;
  const campaignId = campaign?.id || '';
  const campaignStage = campaign?.stage;
  const organizationId = campaign?.organization?.id;
  const file = data.file;

  const { avaSrc, username, link } = getCreatorData(creator);

  const platform =
    creator?.type === 'INSTAGRAM'
      ? instagramColoredImg
      : creator?.type === 'TIKTOK'
      ? tiktokColoredImg
      : undefined;

  const createdAt = file?.createdAt ? createDate(file?.createdAt, 'DD MMM. YYYY') : '';

  const poster = data.file?.transformations?.postThumbnailUrl;

  const projectLink = getProjectsLink({
    projectId,
    campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
    organizationId: admin ? organizationId : undefined,
  });

  const handlePosterClick = () => {
    amplitude.sendEvent<521>({
      id: '521',
      category: 'campaign_performance',
      name: 'approved_creative_poster_click',
      param: { project_id: projectId, campaign_id: campaignId },
    });

    // TODO: replace modal with drawer as separate TECH DEBT task https://insensepro.atlassian.net/browse/DEV-7083
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: CREATIVE_PREVIEW,
        attach: {
          projectId,
          list: [
            {
              ...file,
              typename: MEDIA,
            },
          ],
        },
      },
    });
  };

  const handleChatClick = () => {
    amplitude.sendEvent<520>({
      id: '520',
      category: 'campaign_performance',
      name: 'approved_creative_chat_click',
      param: { project_id: projectId, campaign_id: campaignId },
    });
  };

  const handleUsernameClick = () => {
    amplitude.sendEvent<545>({
      id: '545',
      category: 'campaign_performance',
      name: 'creative_username_click',
      param: { creator_id: creator?.id, campaign_id: campaignId },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.details}>
        <div className={styles.avatarWrap}>
          <Image src={avaSrc} type="round" size={39} imgClassName={styles.ava} />
          {platform && (
            <div className={styles.platform}>
              <img src={platform} className={styles.platformImg} />
            </div>
          )}
        </div>
        <div>
          <div className={styles.usernameWrap}>
            <SmartLink path={link} target="_blank" onClick={handleUsernameClick}>
              <Text type="b2" weight="extraBold" color="textMainLink" text={`@${username}`} />
            </SmartLink>
            <Image src={insenseIconImg} size={20} />
          </div>
          {createdAt && (
            <Text
              type="p"
              weight="medium"
              color="textMainTextTertiary"
              msg="creative.created_at"
              formatValues={{ date: createdAt }}
            />
          )}
        </div>
      </div>
      <div
        className={styles.poster}
        style={{ backgroundImage: `url(${poster})` }}
        onClick={handlePosterClick}
      />
      <SmartLink path={projectLink} className={styles.gotoChatLink} onClick={handleChatClick}>
        <Button fluid type="secondary" style="outlined" size="small" msg="creative.open_chat.btn" />
      </SmartLink>
    </div>
  );
};

export default ApprovedCreative;
