import React, { useCallback, useState, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CreatorCardControls.pcss';
import PublicProjectOffer from './PublicProjectOffer/PublicProjectOffer';

import { CampaignContext } from 'AdvertiserPage/Campaign/Campaign.Context';
import { amplitude } from 'Helpers/amplitude';
import addCreatorToCampaignShortlist from 'Mutations/AddCreatorToCampaignShortlist.Mutation';
import removeCreatorFromCampaignShortlist from 'Mutations/RemoveCreatorFromCampaignShortlist.Mutation';
import createProjectAnalyticsEventMutation from 'Mutations/CreateProjectAnalyticsEvent.Mutation';
import unarchiveProject from 'Mutations/UnarchiveProject.Mutation';
import archiveProject from 'Api/mutations/ArchiveProject.Mutation';
import sendOffer from 'Mutations/SendOffer.Mutation';
import { getCreatorProfileLink } from 'Util/links';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import Image from 'Components/ui/Image/Image';
import Text from 'Components/ui/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import NewNotification from 'Atoms/NewNotification/NewNotification';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { modalName } from 'Types/modals';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';
import {
  AdvertiserProjectStage,
  CreatorCardControls_project$key,
} from 'GraphTypes/CreatorCardControls_project.graphql';
import thumbsUpFilledBlueImg from 'Images/icons/thumbs-up-filled.svg';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import { NotificationsContext, Types } from 'Containers/Notifications/Notifications.Context';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import {
  INVITATION_SENT,
  MESSAGED,
  RECIEVED_APPLICANTS,
} from 'AdvertiserPage/Campaign/NewCampaignTabs/util';

interface Props {
  project: CreatorCardControls_project$key;
  isAdminView: boolean;
  isLongTermCampaign?: boolean;
  canCreateMultipleDeals: boolean;
  organizationId?: string;
  creatorsStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
}

const CreatorCardControls: React.FC<Props> = (props) => {
  const { closeDrawer } = useContext(DrawerContext);
  const { openProjectChat, openArchiveProjectChat, getCallback } = useContext(ChatDrawerContext);
  const handleArchived = getCallback?.('Archived', 'Success');
  const handleUnarchived = getCallback?.('Unarchived', 'Success');

  const { dispatch: notificationsDispatch } = useContext(NotificationsContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { onCheckCreatorsExistence } = useContext(CampaignContext);

  const {
    project,
    isAdminView,
    creatorsStages,
    archivedStages,
    canCreateMultipleDeals,
    isLongTermCampaign,
  } = props;

  const data = useFragment(
    graphql`
      fragment CreatorCardControls_project on Project {
        id
        shortlisted
        price
        advertiserStage
        hasLaunchOrBrandMessage
        events {
          unreadCount
        }
        launch {
          id
        }
        completion {
          id
        }
        offer {
          id
          acceptance {
            id
          }
          rejection {
            id
          }
        }
        campaign {
          type
          id
          completion {
            id
          }
          canDuplicate
          paymentType
          stage
          platform
          publishingRequired
          organization {
            id
          }
        }
        events {
          unreadCount
        }
        archivation(side: ADVERTISER) {
          id
        }
        archivationByContractor: archivation(side: CONTRACTOR) {
          id
        }
        creator {
          id
          username
          type
          ... on InstagramCreator {
            insightsAuthorized
            user {
              id
            }
          }
        }
        contentReview {
          status
        }
        archivationRequest {
          acceptance {
            id
          }
          rejection {
            id
          }
          side
        }
        offer {
          id
          acceptance {
            id
          }
          rejection {
            id
          }
        }
        hasEmailThread
      }
    `,
    project
  );

  const { advertiserStage, shortlisted, events, archivation, archivationByContractor } = data;
  const creatorId = data.creator?.id;
  const username = data.creator?.username;
  const campaignId = data.campaign.id;
  const projectCompletion = data.completion?.id;
  const canDuplicate = data.campaign.canDuplicate;
  const campaignCompletion = data.campaign.completion;
  const projectId = data.id;
  const offer = data.offer;
  const hasOffer = !!offer?.id;
  const hasAcceptance = !!offer?.acceptance?.id;
  const launched = !!data.launch?.id;
  const isRepliedOnOutreachEmail = data.hasEmailThread;

  const showPublicBrief =
    hasOffer &&
    !hasAcceptance &&
    !offer?.rejection?.id &&
    !projectCompletion &&
    isAdminView &&
    !archivation;

  const [sendOfferStart, setSendOfferStart] = useState(false);
  const [unarchiveLoading, setUnarchiveLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const profileLink = getCreatorProfileLink({ creatorId });

  const handleSendOfferDone = () => {
    setSendOfferStart(false);
  };

  const handleSendClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!creatorId) return;

    amplitude.sendEvent({
      id: 103,
      category: 'campaign',
      name: 'creator_send_brief',
      param: {
        creator_id: creatorId,
        campaign_id: campaignId,
      },
    });

    setSendOfferStart(true);
    sendOffer({ campaignId, creatorId }, handleSendOfferDone);
  };

  const unreadCount = events?.unreadCount;

  const chatIcon = (
    <div className={styles.chat}>
      <Icon name="Chat" />
      {!!unreadCount && (
        <NewNotification
          count={unreadCount}
          isActive={true}
          isLimited={true}
          className={styles.unreadCount}
        />
      )}
      <Text type="md" msg="creator.chat" data-test="creatorCardControls:text:chat" />
    </div>
  );

  const handleShortlistClick = useCallback(() => {
    if (!creatorId || !campaignId) return;
    const source = hasAcceptance ? 'creators' : 'awaiting-list';

    if (shortlisted) {
      amplitude.sendEvent<189>({
        id: '189',
        category: 'campaign',
        name: 'campaign_creator_remove_shortlist',
        param: {
          source,
          creator_id: creatorId,
          campaign_id: campaignId,
        },
      });
    } else {
      amplitude.sendEvent<105>({
        id: '105',
        category: 'campaign',
        name: 'campaign_creator_add_shortlist',
        param: {
          source,
          creator_id: creatorId,
          campaign_id: campaignId,
        },
      });
    }

    if (shortlisted) {
      removeCreatorFromCampaignShortlist({ creatorId, campaignId, projectId });
    } else {
      createProjectAnalyticsEventMutation({ projectId, heartAdded: true });
      addCreatorToCampaignShortlist({ creatorId, campaignId, projectId });
    }
  }, [creatorId, campaignId, projectId, shortlisted]);

  const hasArchiveRequest =
    data.archivationRequest &&
    !data.archivationRequest.acceptance &&
    !data.archivationRequest.rejection;

  const canArchive =
    !archivation &&
    advertiserStage !== 'COMPLETED' &&
    !hasArchiveRequest &&
    !campaignCompletion &&
    !archivationByContractor;
  const canUnarchive = archivation && !archivationByContractor && !campaignCompletion;

  const notificationText = username
    ? 'creator_card.notifications.archive'
    : 'creator_card.notifications.default_text';

  const allowToOpenChat = hasAcceptance || isLongTermCampaign || isRepliedOnOutreachEmail;

  const handleArchiveProjectClick = () => {
    amplitude.sendEvent({
      id: 104,
      category: 'campaign',
      name: 'creator_hide',
      param: {
        creator_id: creatorId,
      },
    });

    handleArchiveProject();
  };

  const handleUnarchiveProjectClick = () => {
    setUnarchiveLoading(true);
    handleUnarchiveProject(true);
    handleUnarchived(
      advertiserStage !== 'ACCEPTED'
        ? INVITATION_SENT
        : data?.hasLaunchOrBrandMessage
          ? MESSAGED
          : RECIEVED_APPLICANTS
    );
  };

  const handleUnarchivationDone = () => {
    onCheckCreatorsExistence?.();
  };

  const handleUnarchiveProject = (preventUpdate?: boolean) => {
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { source: 'CREATORS', actionName: 'unarchive', campaign_id: campaignId },
    });

    unarchiveProject(
      {
        projectId,
        campaignId,
        archivedStages,
        isAdmin: isAdminView,
        preventUpdate,
      },
      handleUnarchivationDone
    );
  };

  const handleArchivationDone = () => {
    notificationsDispatch({
      type: Types.ADD_NOTIFICATION,
      payload: {
        item: {
          id: projectId,
          text: notificationText,
          values: { username },
          callback: () => {
            handleUnarchiveProject(false);
          },
        },
      },
    });
    onCheckCreatorsExistence?.();
    handleArchived?.();
  };

  const handleArchiveProject = () => {
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { source: 'CREATORS', actionName: 'archive', campaign_id: campaignId },
    });
    setArchiveLoading(true);
    archiveProject(
      { projectId, campaignId, creatorsStages, archivedStages, isAdmin: isAdminView },
      handleArchivationDone
    );
  };

  const handleDeclineDeal = () => {
    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: { source: 'CREATORS', actionName: 'archive', campaign_id: campaignId },
    });
    openArchiveProjectChat(projectId);
  };

  const handleChatClick = () => {
    amplitude.sendEvent<344>({
      id: '344',
      category: 'chat',
      name: 'open_popup_chat',
      param: {
        project_id: projectId,
        campaign_id: campaignId,
      },
    });
    openProjectChat(projectId);
    createProjectAnalyticsEventMutation({ projectId, chatViewed: true });
  };

  const handleProfileClick = () => {
    createProjectAnalyticsEventMutation({ projectId, profileViewed: true });
  };

  const handleStarNewDealClick = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.START_NEW_DEAL, attach: { campaignId, creatorId, projectId } },
    });
  };

  const closeModal = useCallback(() => {
    closeDrawer(`chat-modal`);
  }, []);

  return (
    <div className={styles.root}>
      {advertiserStage === 'UNSENT' && !allowToOpenChat && !archivation && (
        <AlterButton
          type="grey"
          icon="Send"
          loading={sendOfferStart}
          msg="creator_card.send_brief"
          onClick={handleSendClick}
          data-test="creatorCardControls:alterButton:sendBrief"
        />
      )}
      {canUnarchive && (
        <AlterButton
          type="grey"
          icon="Arrow-big-left"
          msg="creator_card.unarchive_project"
          loading={unarchiveLoading}
          onClick={handleUnarchiveProjectClick}
          data-test="creatorCardControls:alterButton:unarchiveProject"
        />
      )}
      {allowToOpenChat && (
        <Tooltip place="top" id={`chat_${projectId}`} tooltipMsg="creator_card.chat.tooltip">
          <AlterButton
            type="grey"
            rightElement={chatIcon}
            onClick={handleChatClick}
            data-test="creatorCardControls:alterButton:unknown"
          />
        </Tooltip>
      )}
      <SmartLink path={profileLink} target="_blank" onClick={handleProfileClick}>
        <AlterButton
          type="grey"
          msg="creator_card.view_stats"
          icon="Profile"
          data-test="creatorCardControls:alterButton:viewStats"
        />
      </SmartLink>
      {showPublicBrief && <PublicProjectOffer hasOffer={hasOffer} projectId={projectId} />}
      <Tooltip
        place="top"
        id={`shortlisted_${projectId}`}
        tooltipMsg={
          shortlisted
            ? 'creator_card.remove_from_shortlisted.tooltip'
            : 'creator_card.add_from_shortlisted.tooltip'
        }
      >
        <AlterButton
          type="grey"
          rightElement={<Image src={shortlisted ? thumbsUpFilledBlueImg : thumbsUpImg} size={24} />}
          onClick={handleShortlistClick}
          data-test="creatorCardControls:alterButton:unknown"
        />
      </Tooltip>
      {canArchive && !launched && (
        <Tooltip
          place="top"
          id={`hide_${projectId}`}
          tooltipMsg="creator_card.archive_project.tooltip"
        >
          <AlterButton
            type="grey"
            icon="Eye-hidden"
            onClick={handleArchiveProjectClick}
            loading={archiveLoading}
            data-test="creatorCardControls:alterButton:unknown"
          />
        </Tooltip>
      )}
      {canArchive && launched && (
        <Tooltip
          place="top"
          id={`hide_${projectId}`}
          tooltipMsg="creator_card.decline_project.tooltip"
        >
          <AlterButton
            type="grey"
            icon="Trash-delele"
            onClick={handleDeclineDeal}
            data-test="creatorCardControls:alterButton:unknown"
          />
        </Tooltip>
      )}
      {hasArchiveRequest && (
        <Tooltip
          place="top"
          id={`archive_request_${projectId}`}
          tooltipMsg="creator_card.archive_request.tooltip"
        >
          <AlterButton
            type="grey"
            icon="Timer"
            disabled={true}
            data-test="creatorCardControls:alterButton:unknown"
          />
        </Tooltip>
      )}
      {canCreateMultipleDeals && projectCompletion && canDuplicate && (
        <AlterButton
          type="grey"
          icon="Add-plus-circle"
          onClick={handleStarNewDealClick}
          data-test="creatorCardControls:alterButton:unknown"
        />
      )}
    </div>
  );
};

export default CreatorCardControls;
