import React, { useState, useMemo, useContext, useCallback } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import { isBrowser } from 'react-device-detect';
import noop from 'lodash/noop';

import { FilterItem, FilterId } from '../../data';

import styles from './LicenseList.pcss';
import PaidSocialLicenseListItem from './PaidSocialLicenseListItem/PaidSocialLicenseListItem';
import BcaPermissionListItem from './BcaPermissionListItem/BcaPermissionListItem';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Button from 'Components/ui/Button/Button';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Drawer from 'Components/ui/Drawer/Drawer';
import PaidSocialLicenseModal from 'Modal/advertiser/PaidSocialLicenseModal/PaidSocialLicenseModal';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { PARTNERSHIP_ADS_FAQ } from 'Constants/general';
import { LicenseList_organization$data } from 'GraphTypes/LicenseList_organization.graphql';
import {
  default as BcaPermissionModal,
  BcaPermissionModalProps,
} from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModal';
import { BcaPermissionStateEnum } from 'Types/enums';
import { PaidSocialLicensesOrder } from 'GraphTypes/PaidSocialLicenseListQuery.graphql';
import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';

const ITEMS_COUNT = 20;
const FOOTER_OFFSET = 279;

interface Props {
  relay: RelayPaginationProp;
  orderBy: PaidSocialLicensesOrder | undefined;
  filter: FilterItem;
  creatorUsername: string;
  organization: LicenseList_organization$data;
}

const LicenseList: React.FC<Props> = (props) => {
  const { relay, organization, orderBy, filter } = props;
  const [paidSocialLicenceId, setPaidSocialLicenceId] = useState<null | string>(null);
  const [bcaPermissionId, setBcaPermissionId] = useState<null | string>(null);
  const [bcaProjectId, setBcaProjectId] = useState<string>('');

  const { openDrawer } = useContext(DrawerContext);

  const edges = useMemo(() => {
    const isHiddenPaidSocialLicenses = new Set<FilterId>([
      BcaPermissionStateEnum.PENDING_APPROVAL,
      BcaPermissionStateEnum.EXPIRED,
      BcaPermissionStateEnum.REVOKED,
      BcaPermissionStateEnum.CANCELED,
      BcaPermissionStateEnum.INVALID,
    ]).has(filter.id);

    let filteredBcaPermissionsEdges = [];
    if (filter.id === 'default') {
      filteredBcaPermissionsEdges = organization?.bcaPermissions?.edges || [];
    } else {
      filteredBcaPermissionsEdges = (organization?.bcaPermissions?.edges || []).filter((item) => {
        return filter.states.includes(item.node.state);
      });
    }

    const paidSocialLicensesEdges = isHiddenPaidSocialLicenses
      ? []
      : organization?.paidSocialLicenses?.edges || [];

    if (orderBy === 'EXPIRES_AT_ASC') {
      return [].concat(paidSocialLicensesEdges, filteredBcaPermissionsEdges);
    }
    return [].concat(filteredBcaPermissionsEdges, paidSocialLicensesEdges);
  }, [
    filter,
    orderBy,
    organization?.paidSocialLicenses?.edges,
    organization?.bcaPermissions?.edges,
  ]);
  const handleBcaPermissionDrawerClose = () => {
    setBcaPermissionId(null);
  };

  const list = edges?.map((item, index) => {
    if (!item?.node) return null;

    const nodeId = item.node?.id;

    const handlePaidSocialDrawerShow = () => {
      if (nodeId) {
        setPaidSocialLicenceId(nodeId);
      }
    };

    const handleBcaPermissionDrawerShow = (projectId: string) => {
      setBcaProjectId(projectId);
      if (nodeId) {
        setBcaPermissionId(nodeId);
      }
    };

    if (item.node.__typename === 'BcaPermission') {
      return (
        <BcaPermissionListItem
          index={index}
          key={item.node.id}
          bcaPermission={item.node}
          onDrawerShow={handleBcaPermissionDrawerShow}
          onConnectionDone={handleBcaPermissionDrawerClose}
        />
      );
    }

    return null;

    // return (
    //   <PaidSocialLicenseListItem
    //     index={index}
    //     key={item.node.id}
    //     license={item.node}
    //     onDrawerShow={handlePaidSocialDrawerShow}
    //   />
    // );
  });

  const handleBcaConnectionImpossible = useCallback<
    NonNullable<BcaPermissionModalProps['onBcaConnectionImpossible']>
  >(
    (reason) => {
      if (reason === 'maxLicensedCreatorsExceeded') {
        openDrawer('max-licensed-creators-exceeded');
      }
    },
    [openDrawer]
  );

  if (!list || list.length === 0) {
    return (
      <div>
        <div className={styles.emptyList}>
          <Text
            msg="dashboard.advertiser.licenses_section.empty_list"
            formatValues={{
              link: (
                <a href={PARTNERSHIP_ADS_FAQ} target="_blank" className={styles.link}>
                  learn how
                </a>
              ),
            }}
            data-test="licenseList:text:emptyList"
          />
        </div>
        <div className={styles.emptyDescr}>
          <Icon name="Paid-ads" className={styles.ads} />
          <Text
            msg="dashboard.advertiser.licenses_section.empty_list_description"
            color="grey"
            type="s"
            data-test="licenseList:text:emptyListDescription"
          />
        </div>
      </div>
    );
  }

  const startLoadButton = (
    <Button
      color="white"
      msg="dashboard.advertiser.licenses_section.list.load"
      className={styles.loadMore}
      onClick={noop}
      data-test="licenseList:button:load"
    />
  );

  const handlePaidSocialDrawerClose = () => {
    setPaidSocialLicenceId(null);
  };

  return (
    <>
      <LoaderHandler
        relay={relay}
        count={ITEMS_COUNT}
        offset={FOOTER_OFFSET}
        startLoadButton={isBrowser ? startLoadButton : undefined}
        className={styles.root}
      >
        <div className={styles.container}>{list}</div>
      </LoaderHandler>
      <BcaPermissionModal
        drawerProps={{
          opened: Boolean(bcaPermissionId),
          onClose: handleBcaPermissionDrawerClose,
        }}
        onBcaConnectionImpossible={handleBcaConnectionImpossible}
        projectId={bcaProjectId}
      />
      <Drawer
        opened={Boolean(paidSocialLicenceId)}
        onClose={handlePaidSocialDrawerClose}
        rootKey="license"
        className={styles.drawer}
      >
        <PaidSocialLicenseModal id={paidSocialLicenceId} />
      </Drawer>
      <MaxLicensedCreatorsExceededDrawer
        attach={{
          planId: organization.subscription?.planId,
          maxLicensedCreators: organization.subscription?.effectiveLimits.maxBcaConnections,
        }}
      />
    </>
  );
};

export default createPaginationContainer(
  LicenseList,
  {
    organization: graphql`
      fragment LicenseList_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        orderBy: { type: "PaidSocialLicensesOrder" }
        active: { type: "Boolean" }
        states: { type: "[BcaPermissionState!]" }
        creatorUsername: { type: "String" }
      ) {
        paidSocialLicenses: paidSocialLicenses(
          first: $count
          after: $cursor
          orderBy: $orderBy
          active: $active
          creatorUsername: $creatorUsername
        ) {
          edges {
            node {
              id
              ...PaidSocialLicenseListItem_license
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        bcaPermissions(
          first: $count
          after: $cursor
          states: $states
          creatorUsername: $creatorUsername
        ) @connection(key: "LicenseList_bcaPermissions", filters: []) {
          edges {
            node {
              id
              state
              ...BcaPermissionListItem_bcaPermission
              __typename
            }
            cursor
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        subscription {
          planId
          effectiveLimits {
            maxBcaConnections
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization.bcaPermissions;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: props.orderBy,
        creatorUsername: props.creatorUsername,
        active: props.filter.active,
      };
    },
    query: graphql`
      query LicenseListPaginationQuery(
        $count: Int!
        $cursor: String
        $orderBy: PaidSocialLicensesOrder
        $active: Boolean
        $creatorUsername: String
      ) {
        currentUser {
          organization {
            ...LicenseList_organization
              @arguments(
                count: $count
                cursor: $cursor
                orderBy: $orderBy
                active: $active
                creatorUsername: $creatorUsername
              )
          }
        }
      }
    `,
  }
);
