import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import styles from './OrganizationProjects.pcss';
import ProjectsSidebar from './ProjectsSidebar/ProjectsSidebar';
import SelectedProject from './SelectedProject/SelectedProject';

import { getQueryLocationParams } from 'Util/encodeQueryData';
import { amplitude } from 'Helpers/amplitude';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import { OrganizationProjectsQuery as QueryType } from 'GraphTypes/OrganizationProjectsQuery.graphql';

const OrganizationProjectsQuery = graphql`
  query OrganizationProjectsQuery {
    currentUser {
      type
      organization {
        id
        projects(first: 1) {
          edges {
            node {
              campaign {
                showShipmentTab
                id
              }
            }
          }
        }
      }
    }
  }
`;

const OrganizationProjects = () => {
  const { projectId: defaultProjectId, organizationId: defaultOrganizationId } =
    getQueryLocationParams();

  const location = useLocation();

  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(
    defaultProjectId as string
  );

  const { currentUser } = useLazyLoadQuery<QueryType>(OrganizationProjectsQuery, {});

  const organizationId = defaultOrganizationId || currentUser?.organization?.id;
  const campaignId = currentUser?.organization?.projects?.edges?.[0]?.node?.campaign?.id || '';
  const showShipmentTab =
    currentUser?.organization?.projects?.edges?.[0]?.node?.campaign?.showShipmentTab;

  useEffect(() => {
    amplitude.sendEvent({
      id: '308',
      category: 'pageview',
      name: 'chats',
    });
  }, []);

  useEffect(() => {
    const { projectId } = getQueryLocationParams();
    if (selectedProjectId !== projectId) {
      setSelectedProjectId(projectId as string);
    }
  }, [location.search]);

  return (
    <Page className={styles.root}>
      <Suspense fallback={<Spinner className={styles.preloader} />}>
        <ProjectsSidebar
          organizationId={organizationId as string}
          selectedProjectId={selectedProjectId}
        />
        <SelectedProject
          projectId={selectedProjectId}
          campaignId={campaignId}
          shipmentEnabled={showShipmentTab}
        />
      </Suspense>
    </Page>
  );
};

export default OrganizationProjects;
