import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './Campaign.pcss';
import CampaignPreviewCounters from './CampaignPreviewCounters/CampaignPreviewCounters';

import { createDate } from 'Util/dateCreator';
import { createSum } from 'Util/numberFormatter';
import { SubscriptionParamsType } from 'Helpers/amplitude.types';
import { getCampaignTypeLink } from 'Util/links';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Image, { ImagePlatform } from 'Components/ui/Image/Image';
import Tag from 'Components/ui/Tag/Tag';
import TextButton from 'Components/ui/TextButton/TextButton';
import Icon from 'Components/ui/Icon/Icon';
import CampaignSubscription from 'Organisms/CampaignSubscription/CampaignSubscription';
import {
  Campaign_campaign$data,
  Campaign_campaign$key,
} from 'GraphTypes/Campaign_campaign.graphql';
import { BILLING_ROUTE, PLANS_ROUTE } from 'Constants/general';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  isAdmin: boolean;
  campaign: Campaign_campaign$key;
  handleCampaignMenu: (campaign: Campaign_campaign$data) => void;
  organizationId?: string;
}

const Campaign: React.FC<Props> = (props) => {
  const { isAdmin, campaign, handleCampaignMenu, organizationId: userOrganizationId } = props;

  const [detailsOpened, setDetailsOpened] = useState(false);

  const data = useFragment(
    graphql`
      fragment Campaign_campaign on Campaign {
        platform
        type
        postingType
        id
        name
        stage
        createdAt
        canDuplicate
        useCase
        brand {
          name
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
        briefSubmission {
          acceptance {
            id
          }
        }
        counters {
          applicants
          launchedProjects
          completedProjects
          totalSpend
        }
        activation {
          id
          paused
        }
        deletable
        canBeCompleted
        completion {
          __typename
        }
        completionRequest {
          __typename
        }
        organization {
          id
          subscription {
            planId
            paused
          }
        }
        userSubscribed
        brief {
          ... on V2Brief {
            productDelivery
          }
        }
        ...CampaignPreviewCounters_campaign
      }
    `,
    campaign
  );
  const {
    id: campaignId,
    name,
    platform,
    brand,
    type,
    postingType,
    counters,
    completionRequest,
    stage,
    organization,
    userSubscribed,
    useCase,
  } = data;

  const organizationId = organization?.id;

  const launchedProjects =
    typeof counters?.launchedProjects === 'number' ? counters.launchedProjects : '—';
  const completedProjects =
    typeof counters?.completedProjects === 'number' ? counters.completedProjects : '—';
  const applicationsCount = typeof counters?.applicants === 'number' ? counters.applicants : '—';
  const completedCount = counters?.completedProjects.toString() || '—';
  const totalSpendValue =
    typeof counters?.totalSpend === 'number' ? createSum(counters.totalSpend, 'USD') : '—';

  const hasFreePlan = organization?.subscription?.planId === 'FREE';
  const isPlanPaused = Boolean(organization?.subscription?.paused);

  const intl = useIntl();

  const campaignLink = getCampaignTypeLink({ campaignId });

  const postingSubtitle = postingType
    ? intl.formatMessage({ id: `campaign.preview.type.${postingType.toLowerCase()}` })
    : null;
  const typeSubtitle = type
    ? intl.formatMessage({ id: `campaign.preview.type.${type.toLowerCase()}` })
    : null;

  const subtitleText = useCase
    ? intl.formatMessage({ id: `campaign.preview.type.${useCase.toLowerCase()}` })
    : postingSubtitle || typeSubtitle;

  const campaignOnPause = data.activation?.paused;
  const createdAt = data.createdAt;

  const isOrganizationMember = organizationId === userOrganizationId;

  const sendAnalyticData = () => {
    amplitude.sendEvent({
      id: 113,
      name: 'campaign_click',
      category: 'dashboard',
      param: { campaign_id: campaignId },
    });
  };

  const openCampaignMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleCampaignMenu(data);
  };

  const handlePlansClick = (e: React.MouseEvent<HTMLElement>) => {
    amplitude.sendEvent<352>({
      id: '352',
      category: 'campaign',
      name: 'buy_subscription_to_activate_click',
      param: { campaign_id: campaignId },
    });
    e.stopPropagation();
  };

  const handleArrowClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!detailsOpened) {
      amplitude.sendEvent<418>({
        id: '418',
        category: 'dashboard',
        name: 'campaign_unfold_click',
        param: { campaign_id: campaignId },
      });
    }
    setDetailsOpened(!detailsOpened);
  };

  const handleSubscriptionStateChange = (param: SubscriptionParamsType) => {
    amplitude.sendEvent<415>({
      id: '415',
      category: 'dashboard',
      name: 'email_notifications_click',
      param,
    });
  };

  return (
    <Link to={campaignLink} onClick={sendAnalyticData} className={styles.root}>
      <div className={styles.row} data-test="campaign:campaignListItem">
        <div className={styles.campaign}>
          <Image
            src={brand?.logo?.transformations?.brandLogoUrl}
            type="round"
            size={40}
            platform={platform as ImagePlatform}
            platformClassName={styles.platform}
          />
          <div className={styles.headerText}>
            <Text type="md" text={name || ''} data-test="campaign:text:unknown" />
            <Text
              type="sm"
              text={`${brand?.name} — ${subtitleText}`}
              className={styles.subtitle}
              data-test="campaign:text:subtitle"
            />
          </div>
        </div>
        {(stage === 'ACTIVE' || stage === 'IN_REVIEW') && (
          <CampaignPreviewCounters
            organizationId={organizationId}
            campaign={data}
            isAdmin={isAdmin}
            campaignStage={stage}
          />
        )}
        {stage === 'DRAFT' && (
          <Text
            type="md"
            text={createDate(createdAt, 'DD MMMM YYYY, HH:mm')}
            data-test="campaign:text:unknown"
          />
        )}
        {stage === 'COMPLETED' && (
          <>
            <Text
              type="md"
              text={completedCount}
              className={styles.closedCount}
              data-test="campaign:text:closedCount"
            />
            <CampaignPreviewCounters
              organizationId={organizationId}
              campaign={data}
              isAdmin={isAdmin}
              campaignStage={stage}
              unread={false}
              withNewApplicants={false}
            />
            <Text type="md" text={totalSpendValue} data-test="campaign:text:unknown" />
          </>
        )}
        {(stage === 'DRAFT' || stage === 'COMPLETED') && <div className={styles.spacer} />}
        <div className={styles.tags}>
          {stage === 'ACTIVE' && (
            <Tag
              msg="campaign.preview.tag.active"
              color="grass"
              icon="Arrow-up-right"
              needMargin={false}
              className={styles.tag}
              data-test="campaign:tag:active"
            />
          )}
          {stage === 'IN_REVIEW' && (
            <Tag
              msg="campaign.preview.tag.on_moderation"
              color="transparent"
              icon="Timer"
              needMargin={false}
              dataTest="tag:campaignListItemOnModeration"
              className={styles.tag}
              data-test="campaign:tag:onModeration"
            />
          )}
          {stage === 'DRAFT' && hasFreePlan && (
            <Link to={PLANS_ROUTE} target="_blank" onClick={handlePlansClick}>
              <Tag
                msg="campaign.preview.tag.subscribe"
                needMargin={false}
                color="sun"
                icon="Lock"
                className={styles.tag}
                data-test="campaign:tag:subscribe"
              />
            </Link>
          )}
          {stage === 'DRAFT' && isPlanPaused && (
            <Link to={BILLING_ROUTE} target="_blank">
              <Tag
                msg="campaign.preview.tag.pause_plan"
                needMargin={false}
                color="brown"
                icon="Lock"
                className={styles.tag}
                data-test="campaign:tag:pausePlan"
              />
            </Link>
          )}
          {stage === 'DRAFT' && (
            <Tag
              msg="campaign.preview.tag.draft"
              needMargin={false}
              color="grey"
              icon="Edit"
              className={styles.tag}
              data-test="campaign:tag:draft"
            />
          )}
          {completionRequest?.__typename === 'CampaignCompletionRequest' &&
            stage !== 'COMPLETED' && (
              <Tag
                msg="campaign.preview.tag.completion"
                needMargin={false}
                color="sun"
                icon="Time-clock"
                className={styles.tag}
                data-test="campaign:tag:completion"
              />
            )}
          {campaignOnPause && stage !== 'COMPLETED' && (
            <Tag
              msg="campaign.preview.tag.paused"
              needMargin={false}
              color="brown"
              icon="Eye-hidden"
              className={styles.tag}
              data-test="campaign:tag:paused"
            />
          )}
          {stage === 'COMPLETED' && (
            <Tag
              msg="campaign.preview.tag.closed"
              needMargin={false}
              color="ocean"
              icon="Check"
              className={styles.tag}
              data-test="campaign:tag:closed"
            />
          )}
        </div>
        {stage === 'ACTIVE' && (
          <TextButton
            icon="Arrow-small-down"
            onClick={handleArrowClick}
            className={styles.arrow}
            data-test="campaign:textButton:arrow"
          />
        )}
      </div>
      <div className={classnames(styles.row, { [styles.hidden]: !detailsOpened })}>
        <div className={styles.counters}>
          <Text
            type="md"
            msg="campaign.preview.counters.applicants"
            formatValues={{ applicationsCount }}
            data-test="campaign:text:applicants"
          />
          <Text
            type="md"
            msg="campaign.preview.counters.launched_projects"
            formatValues={{ launchedProjects }}
            data-test="campaign:text:launchedProjects"
          />
          <Text
            type="md"
            msg="campaign.preview.counters.completed_projects"
            formatValues={{ completedProjects }}
            data-test="campaign:text:completedProjects"
          />
        </div>
        <div className={styles.spacer} />
        <div className={styles.additional}>
          {isOrganizationMember && (
            <Tooltip
              id={`subscribe-${campaignId}`}
              place="top"
              tooltipMsg={`campaign.notification.${userSubscribed ? 'on' : 'off'}.tooltip`}
            >
              <CampaignSubscription
                campaignId={campaignId}
                userSubscribed={userSubscribed}
                eventFn={handleSubscriptionStateChange}
              >
                <Icon
                  name={userSubscribed ? 'Bell-notifications' : 'Bell-notifications-disabled'}
                  className={userSubscribed ? undefined : styles.grey}
                />
              </CampaignSubscription>
            </Tooltip>
          )}
          <Icon onClick={openCampaignMenu} name={'Dots-menu'} />
        </div>
      </div>
    </Link>
  );
};

export default Campaign;
